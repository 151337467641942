import Loadable from "@loadable/component";

import TimelineSkeleton from "components/Timeline/TimelineSkeleton";

const Dashboard = Loadable(() =>
  import(/* webpackChunkName: "Dashboard" */ "./Dashboard")
);

function DashboardLoading(props) {
  return <TimelineSkeleton />;
}

const DashboardContainer = (props) => {
  return <Dashboard {...props} fallback={<DashboardLoading />} />;
};

export default DashboardContainer;
