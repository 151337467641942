import { memo, useCallback, useMemo } from "react";
import Helmet from "react-helmet-async";
import { Redirect, useLocation } from "react-router-dom";

import HomepageTourPrompt from "components/SiteTour/HomepageTourPromptAsync";

import Dashboard from "./DashboardAsync";
import HomepageFeed from "./HomepageFeed/HomepageFeedAsync";
import LoggedOutHomepageContainer from "./LoggedOutHomepageContainerAsync";

import modalActions from "actions/modals";
import { generateMetaArray } from "utils/meta";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import { useLocalStorage } from "hooks/useLocalStorage";
import useLoggedInUser, { useLoggedIn } from "hooks/useLoggedInUser";
import useSiteTourDismissed from "hooks/useSiteTourDismissed";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

const GUESTS_AND_RELEASES_ONLY_PATHNAME = "/myfeed_filtered";

const TITLE = "Podchaser – Podcast Reviews, Credits, Playlists, & More";
const DESCRIPTION =
  "Use Podchaser's extensive podcast database, advanced search and list" +
  " functionality to follow, share and rate your favorite podcasts and episodes.";

const SCRIPT = [
  {
    type: "application/ld+json",
    innerHTML: JSON.stringify({
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: "https://www.podchaser.com/",
      potentialAction: {
        "@type": "SearchAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate:
            "https://www.podchaser.com/search/podcasts/q/{search_term_string}/",
        },
        "query-input": "required name=search_term_string",
      },
    }),
  },
];

export const POPULAR_TAB_KEY = "/popular";
export const PODCHASER_PRO_TAB_KEY = "#";
export const MY_FEED_TAB_KEY = "/myfeed";
export const DEFAULT_TAB = POPULAR_TAB_KEY;

const HomepageView = (props) => {
  const user = useLoggedInUser();
  const isLoggedIn = useLoggedIn();
  const hasPro = useUserHasPro();

  const { hideTour } = useSiteTourDismissed("homepage");

  const location = useLocation();
  const { pathname } = location;

  const [guestsAndReleasesOnly, setGuestsAndReleasesOnly] = useLocalStorage(
    "podchaser:myfeed_guests_and_releases_only",
    { withCookie: true }
  );

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const onDemoRequestSubmitted = useCallback(() => {
    sendGAEvent({
      action: `podchaserProHomePageTabClicked-Submitted`,
      page: "PodchaserHomePage",
      context: "ProLeadForm",
    });
  }, []);

  const handleProClick = useCallback(
    (event) => {
      event?.preventDefault();

      if (!hasPro) {
        sendGAEvent({
          action: `podchaserProHomePageTabClicked`,
          page: "PodcastPublicInsightsTab",
          context: "ProLeadForm",
        });
        showModal("proLeadForm", {
          onDemoRequestSubmitted: () => onDemoRequestSubmitted(),
        });
      }
    },
    [onDemoRequestSubmitted, hasPro, showModal]
  );

  const tabs = useMemo(
    () => [
      {
        key: POPULAR_TAB_KEY,
        name: "Popular",
        title: "Popular Feed",
        description: "The latest in podcasting",
        feed: "public2:3",
        root: user ? false : DEFAULT_TAB === POPULAR_TAB_KEY,
      },
      {
        key: MY_FEED_TAB_KEY,
        fallbackKeys: ["/myfeed_filtered"],
        name: "My Feed",
        title: "My Feed",
        titleLoggedIn: "[username]'s Feed",
        description: "Personalized for you",
        feed: "timeline_aggregated",
        fallbackFeeds: { "/myfeed_filtered": "timeline_filtered" },
        toggles: [
          {
            key: "my-feed",
            onPath: GUESTS_AND_RELEASES_ONLY_PATHNAME,
            offPath: "/myfeed",
            onHeading: "My Feed",
            onHeadingLoggedIn: "[username]'s Feed",
            label: "New Releases & Guests Only",
            onClick: () => setGuestsAndReleasesOnly(!guestsAndReleasesOnly),
          },
        ],
        myFeed: true,
        root: user ? true : DEFAULT_TAB === MY_FEED_TAB_KEY,
      },
      !hasPro && {
        key: "PRO_TAB",
        name: mobile ? "Pro" : "Podchaser Pro",
        title: mobile ? "Pro" : "Podchaser Pro",
        description: "Podcast Media Database",
        feed: "public2:3",
        onClick: handleProClick,
        root: user ? false : DEFAULT_TAB === MY_FEED_TAB_KEY,
      },
    ],
    [
      user,
      hasPro,
      mobile,
      handleProClick,
      setGuestsAndReleasesOnly,
      guestsAndReleasesOnly,
    ]
  );

  const canonicalUrl = useMemo(() => {
    const canUrl = "https://www.podchaser.com";
    const currentTab = tabs.find((tab) => pathname.includes(tab.key));

    if (currentTab) {
      return `${canUrl}${currentTab.key}`;
    }

    return canUrl;
  }, [pathname, tabs]);

  const getTimelineKey = useCallback(
    (currentTab) => {
      if (currentTab.myFeed) {
        const fallbackFeed = currentTab.fallbackFeeds[pathname];

        return user
          ? `${fallbackFeed || currentTab.feed}:${user.get("id")}`
          : "not_logged_in";
      }

      return currentTab.feed;
    },
    [user, pathname]
  );

  const renderFeed = useCallback(
    (currentTab) =>
      currentTab && (
        <HomepageFeed
          currentTab={currentTab}
          user={user}
          mobile={mobile}
          getTimelineKey={getTimelineKey}
        />
      ),
    [user, mobile, getTimelineKey]
  );

  const tabProps = useMemo(
    () => ({
      mobile,
    }),
    [mobile]
  );

  const helmetLink = useMemo(
    () => [{ rel: "canonical", href: canonicalUrl }],
    [canonicalUrl]
  );

  const helmetMeta = useMemo(
    () =>
      generateMetaArray({
        title: TITLE,
        description: DESCRIPTION,
        url: canonicalUrl,
      }),
    [canonicalUrl]
  );

  return (
    <>
      <Helmet
        encodeSpecialCharacters={false}
        script={SCRIPT}
        titleTemplate={null}
        link={helmetLink}
        meta={helmetMeta}
      />
      {!hideTour && <HomepageTourPrompt />}
      {guestsAndReleasesOnly &&
        tabProps.currentTab &&
        tabProps.currentTab.myFeed && (
          <Redirect to={GUESTS_AND_RELEASES_ONLY_PATHNAME} />
        )}
      {isLoggedIn ? (
        <Dashboard tabs={tabs} tabProps={tabProps} renderFeed={renderFeed} />
      ) : (
        <LoggedOutHomepageContainer
          tabs={tabs}
          tabProps={tabProps}
          renderFeed={renderFeed}
        />
      )}
    </>
  );
};

export default memo(HomepageView);
